<template>
  <div>
    <div class="header">
      <img src="@/assets/images/header.png" width="100%" />
    </div>
    <div class="navbar szse-navbar szse-navbar-pc">
      <div class="g-container clearfix">
        <div class="navbar-headers ">
          <a href="#" class="navbar-brand ennavbar-brand">
            <img
              class="nav-logo"
              src="@/assets/images/logo.png"
              alt="Shenzhen Stock Exchange"
            />
          </a>
        </div>
        <div class="pull-right langswitch-con">
          <a class="langeach cn btn-golangcn" href="#">中文</a>
          <!-- <span class="sepline">|</span>
            <a class="langeach ft btn-golangft">繁体</a> -->
        </div>
        <div class="top-search-el">
          <form
            class="search-form sh-searchhint-container"
            action=""
            target="frameFile"
            style="position: relative;"
          >
            <input
              type="search"
              maxlength="100"
              class="search-input need-repaired-placeholder"
              autocomplete="off"
              placeholder="Search"
            />
            <input type="text" style="display: none;" />
            <span class="clear-keyword">×</span>
            <button class="search-btn" type="button" disabled>
              <span class="glyphicon glyphicon-search"></span>
            </button>
          </form>
          <div class="search-hot" style="display:none;"></div>
        </div>
      </div>
      <nav
        class="collapse navbar-collapse bs-navbar-collapse navbar-wrap"
        role="navigation"
      >
        <div class="g-container">
          <div class="nav-box bondnav-box">
            <ul class="nav navbar-nav">
              <li chnlcode="home" class="active">
                <a datapath="home" href="#">HOME</a>
              </li>
              <li chnlcode="listings">
                <a datapath="listings">LISTING</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="whySzse">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="whySzse">Why SZSE</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="standards">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="standards"
                              >Listing Standards</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="process">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="process">Listing Process</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="listFees">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="listFees">Listing Fees</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="faq">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="faq"
                              >Frequently Asked Questions</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="disclosures">
                <a datapath="disclosures">DISCLOSURE</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="announcements">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="announcements"
                              >Company Announcements</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="profiles_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="profiles_link"
                              >Company Profiles</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="fund">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="fund">Fund Information</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="siteMarketData">
                <a datapath="siteMarketData">MARKET DATA</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="siteMarketDatas">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Market Data</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Stocks</a>

                            <a href="#"><i></i>Funds</a>

                            <a href="#"><i></i>Quotes Lookup</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="marketStatistics">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Market Statistics</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Market Overview</a>

                            <a href="#"><i></i>Securities Summary</a>

                            <a href="#"><i></i>Sector Summary</a>

                            <a href="#"><i></i>Top Counters</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="indices">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Indices</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Indices Performance</a>

                            <a href="#"><i></i>Indices Constituent</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="publication">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Publication</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Monthly Report</a>

                            <a href="#"><i></i>Fact Book</a>

                            <a href="#"><i></i>Research</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="products">
                <a datapath="products">PRODUCTS</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="equity">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Equity</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Overview</a>

                            <a href="#"><i></i>Main Board</a>

                            <a href="#"><i></i>ChiNext</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="lefuFunds">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="lefuFunds">Fund</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="bonds">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="bonds">Bonds</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="security">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="security"
                              >Asset-Backed Securities</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="options">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Options</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Overview</a>

                            <a href="#"><i></i>CSI 300 ETF Options</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="services">
                <a datapath="services">SERVICES</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="trading">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Trading</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Overview</a>

                            <a href="#"><i></i>Trading Calendar</a>

                            <a href="#"><i></i>Trading Fees</a>

                            <a href="#"><i></i>Margin Trading</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="membership">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="membership"
                              >Membership Services</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="stockConnect_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="stockConnect_link"
                              >Stock Connect</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="qr_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="qr_link">QFII &amp; RQFII</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="dataServices">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="dataServices"
                              >Data Services</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="siteRules">
                <a datapath="rules">RULES</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="siteRule">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="siteRule">SZSE Rules</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="enAbout">
                <a datapath="about">ABOUT</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="enAboutOverview">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enAboutOverview">Overview</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enAboutNews">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">News</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Speeches</a>

                            <a href="#"><i></i>SZSE News</a>

                            <a href="#"><i></i>New Listings</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enMilestones">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enMilestones">Milestones</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enOurBrand">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enOurBrand">Our Brand</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enOrganization">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enOrganization"
                              >Organization</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enMembers">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enMembers">Members</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enCorporate">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enCorporate"
                              >Corporate Social Responsibility</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enCrossborder_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enCrossborder_link"
                              >Cross-border Capital Services</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enContactus">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enContactus">Contact Us</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div class="navbar-fixed-top navmobile szse-navbar szse-navbar-mobile">
      <div class="mobile-search">
        <div class="mobile-search_container">
          <div class="mobile-search_form">
            <div class="top-search-el">
              <div
                class="search-form sh-searchhint-container"
                style="position: relative;"
              >
                <form action="" target="frameFile" id="searh_form">
                  <input
                    type="search"
                    class="mobile-search_input search-input need-repaired-placeholder"
                    placeholder="Search"
                  />
                  <input type="text" style="display: none;" />
                  <iframe name="frameFile" style="display: none;"></iframe>
                </form>
              </div>
            </div>
            <!-- <input class="mobile-search_input" type="text" name="topbarSearch" placeholder="搜索行情/公告/其他信息" /> -->
            <img
              class="mobile-search_close"
              src="@/assets/images/mobile-search_close.png"
              alt="search close"
            />
          </div>
        </div>
      </div>
      <div id="dl-menu" class="dl-menuwrapper">
        <a class="navipone_block"
          ><span class="glyphicon glyphicon-search"></span
        ></a>
        <a class="navbar-brand  ennavbar-brand" href="#"
          ><img src="@/assets/images/logo.png" alt="Shenzhen Stock Exchange"
        /></a>
        <button class="dl-trigger dl-phmenu"></button>

        <ul class="dl-menu">
          <li chnlcode="home" class="active">
            <a data-level="HOME" href="#">HOME</a>
          </li>
          <li chnlcode="listings">
            <a href="#">LISTING</a>
            <ul class="dl-submenu">
              <li chnlcode="listings">
                <a data-level="Why SZSE" href="#">Why SZSE</a>
              </li>
              <li chnlcode="listings">
                <a data-level="Listing Standards" href="#">Listing Standards</a>
              </li>
              <li chnlcode="listings">
                <a data-level="Listing Process" href="#">Listing Process</a>
              </li>
              <li chnlcode="listings">
                <a data-level="Listing Fees" href="#">Listing Fees</a>
              </li>
              <li chnlcode="listings">
                <a data-level="Frequently Asked Questions" href="#"
                  >Frequently Asked Questions</a
                >
              </li>
            </ul>
          </li>
          <li chnlcode="disclosures">
            <a href="#">DISCLOSURE</a>
            <ul class="dl-submenu">
              <li chnlcode="disclosures">
                <a data-level="Company Announcements" href="#"
                  >Company Announcements</a
                >
              </li>
              <li chnlcode="disclosures">
                <a data-level="Company Profiles" href="#">Company Profiles</a>
              </li>
              <li chnlcode="disclosures">
                <a data-level="Fund Information" href="#">Fund Information</a>
              </li>
            </ul>
          </li>
          <li chnlcode="siteMarketData">
            <a href="#">MARKET DATA</a>
            <ul class="dl-submenu">
              <li chnlcode="siteMarketData">
                <a href="#">Market Data</a>
                <ul class="dl-submenu">
                  <li chnlcode="siteMarketData">
                    <a data-level="Stocks" href="#">Stocks</a>
                  </li>
                  <li chnlcode="siteMarketData">
                    <a data-level="Funds" href="#">Funds</a>
                  </li>
                  <li chnlcode="siteMarketData">
                    <a data-level="Quotes Lookup" href="#">Quotes Lookup</a>
                  </li>
                </ul>
              </li>
              <li chnlcode="siteMarketData">
                <a href="#">Market Statistics</a>
                <ul class="dl-submenu">
                  <li chnlcode="siteMarketData">
                    <a data-level="Market Overview" href="#">Market Overview</a>
                  </li>
                  <li chnlcode="siteMarketData">
                    <a data-level="Securities Summary" href="#"
                      >Securities Summary</a
                    >
                  </li>
                  <li chnlcode="siteMarketData">
                    <a data-level="Sector Summary" href="#">Sector Summary</a>
                  </li>
                  <li chnlcode="siteMarketData">
                    <a data-level="Top Counters" href="#">Top Counters</a>
                  </li>
                </ul>
              </li>
              <li chnlcode="siteMarketData">
                <a href="#">Indices</a>
                <ul class="dl-submenu">
                  <li chnlcode="siteMarketData">
                    <a data-level="Indices Performance" href="#"
                      >Indices Performance</a
                    >
                  </li>
                  <li chnlcode="siteMarketData">
                    <a data-level="Indices Constituent" href="#"
                      >Indices Constituent</a
                    >
                  </li>
                </ul>
              </li>
              <li chnlcode="siteMarketData">
                <a href="#">Publication</a>
                <ul class="dl-submenu">
                  <li chnlcode="siteMarketData">
                    <a data-level="Monthly Report" href="#">Monthly Report</a>
                  </li>
                  <li chnlcode="siteMarketData">
                    <a data-level="Fact Book" href="#">Fact Book</a>
                  </li>
                  <li chnlcode="siteMarketData">
                    <a data-level="Research" href="#">Research</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li chnlcode="products">
            <a href="#">PRODUCTS</a>
            <ul class="dl-submenu">
              <li chnlcode="products">
                <a href="#">Equity</a>
                <ul class="dl-submenu">
                  <li chnlcode="products">
                    <a data-level="Overview" href="#">Overview</a>
                  </li>
                  <li chnlcode="products">
                    <a data-level="Main Board" href="#">Main Board</a>
                  </li>
                  <li chnlcode="products">
                    <a data-level="ChiNext" href="#">ChiNext</a>
                  </li>
                </ul>
              </li>
              <li chnlcode="products">
                <a data-level="Fund" href="#">Fund</a>
              </li>
              <li chnlcode="products">
                <a data-level="Bonds" href="#">Bonds</a>
              </li>
              <li chnlcode="products">
                <a data-level="Asset-Backed Securities" href="#"
                  >Asset-Backed Securities</a
                >
              </li>
              <li chnlcode="products">
                <a href="#">Options</a>
                <ul class="dl-submenu">
                  <li chnlcode="products">
                    <a data-level="Overview" href="#">Overview</a>
                  </li>
                  <li chnlcode="products">
                    <a data-level="CSI 300 ETF Options" href="#"
                      >CSI 300 ETF Options</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li chnlcode="services">
            <a href="#">SERVICES</a>
            <ul class="dl-submenu">
              <li chnlcode="services">
                <a href="#">Trading</a>
                <ul class="dl-submenu">
                  <li chnlcode="services">
                    <a data-level="Overview" href="#">Overview</a>
                  </li>
                  <li chnlcode="services">
                    <a data-level="Trading Calendar" href="#"
                      >Trading Calendar</a
                    >
                  </li>
                  <li chnlcode="services">
                    <a data-level="Trading Fees" href="#">Trading Fees</a>
                  </li>
                  <li chnlcode="services">
                    <a data-level="Margin Trading" href="#">Margin Trading</a>
                  </li>
                </ul>
              </li>
              <li chnlcode="services">
                <a data-level="Membership Services" href="#"
                  >Membership Services</a
                >
              </li>
              <li chnlcode="services">
                <a data-level="Stock Connect" href="#">Stock Connect</a>
              </li>
              <li chnlcode="services">
                <a data-level="QFII &amp; RQFII" href="#">QFII &amp; RQFII</a>
              </li>
              <li chnlcode="services">
                <a data-level="Data Services" href="#">Data Services</a>
              </li>
            </ul>
          </li>
          <li chnlcode="siteRules">
            <a href="#">RULES</a>
            <ul class="dl-submenu">
              <li chnlcode="siteRules">
                <a data-level="SZSE Rules" href="#">SZSE Rules</a>
              </li>
            </ul>
          </li>
          <li chnlcode="enAbout">
            <a href="#">ABOUT</a>
            <ul class="dl-submenu">
              <li chnlcode="enAbout">
                <a data-level="Overview" href="#">Overview</a>
              </li>
              <li chnlcode="enAbout">
                <a href="#">News</a>
                <ul class="dl-submenu">
                  <li chnlcode="enAbout">
                    <a data-level="Speeches" href="#">Speeches</a>
                  </li>
                  <li chnlcode="enAbout">
                    <a data-level="SZSE News" href="#">SZSE News</a>
                  </li>
                  <li chnlcode="enAbout">
                    <a data-level="New Listings" href="#">New Listings</a>
                  </li>
                </ul>
              </li>
              <li chnlcode="enAbout">
                <a data-level="Milestones" href="#">Milestones</a>
              </li>
              <li chnlcode="enAbout">
                <a data-level="Our Brand" href="#">Our Brand</a>
              </li>
              <li chnlcode="enAbout">
                <a data-level="Organization" href="#">Organization</a>
              </li>
              <li chnlcode="enAbout">
                <a data-level="Members" href="#">Members</a>
              </li>
              <li chnlcode="enAbout">
                <a data-level="Corporate Social Responsibility" href="#"
                  >Corporate Social Responsibility</a
                >
              </li>
              <li chnlcode="enAbout">
                <a data-level="Cross-border Capital Services" href="#"
                  >Cross-border Capital Services</a
                >
              </li>
              <li chnlcode="enAbout">
                <a data-level="Contact Us" href="#">Contact Us</a>
              </li>
            </ul>
          </li>
          <li class="otherinfo-li">
            <div class="otherinfo">
              <div class="top-menu-list">
                <div class="item item-home">
                  <a class="link-home" href="#">中文</a>
                </div>
                <div class="item item-loginregist" style="display: none;">
                  <a class="link-login" href="#">个人中心</a>
                </div>
                <div class="item item-english" style="display: none;">
                  <a class="link-english" href="">English</a>
                </div>

                <div class="item languageswitch" style="display: none;">
                  <a class="big5OrSimp" href="">繁体中文</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="g-locationnav">
      <div class="banner-wrap">
        <img
          src="http://res.static.szse.cn/enweb/common/images/commonbannerbg.jpg"
        />
      </div>
      <div class="g-container">
        <div class="g-banner-txt">
          <h2 class="hidden-xs">SZSE English</h2>
          <p>
            <img
              src="http://res.static.szse.cn/common/icon/er-banneradr.png"
            />LOCATION:
            <a href="./" class="CurrChnlCls">SZSE English</a>
          </p>
        </div>
      </div>
    </div>
    <!--207 -->
    <div class="indexbanner-wrap en-banner">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/lotto-landingpage.appspot.com/o/images%2FSHENZHEN-VISA%201500.jpg?alt=media&token=7645bd08-ed1d-4689-bc5f-eb49e892d99c"
        width="100%"
      />
    </div>
    <div class="home-entrance-wrap">
      <div class="g-container">
        <ul class="list clearfix">
          <li>
            <a href="#">
              <div class="icon_box clearfix">
                <span class="icon stocks-icon"></span>
                <span class="icon-text">STOCKS</span>
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="icon_box clearfix">
                <span class="icon funds-icon"></span>
                <span class="icon-text">FUNDS</span>
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="icon_box clearfix">
                <span class="icon bonds-icon"></span>
                <span class="icon-text">BONDS</span>
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="icon_box clearfix">
                <span class="icon options-icon"></span>
                <span class="icon-text">OPTIONS </span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="component">
      <div class="g-container">
        <h2 class="news-tit text-center">
          SHENZHEN&nbsp;VISA&nbsp;COMPONENT&nbsp;INDEX
        </h2>
        <div class="list-wrap">
          <div tabindex="0" class="c-selectex-container">
            <div class="dropdown">
              <a
                class="btn btn-default c-selectex-btn dropdown-btn"
                data-s-dropdown-id="#c-selectex-menu-2"
              >
                <span
                  tabindex="0"
                  class="c-selectex-btn-text undefined"
                  style="display: block;"
                  title="Shenzhen Component Index"
                  >Shenzhen Component Index</span
                >
                <span class="glyphicon glyphicon-menu-down"></span>
              </a>
              <ul id="c-selectex-menu-2" class="dropdown-menu">
                <li class="active">
                  <a
                    title="Shenzhen Component Index"
                    data-id="0"
                    data-value="399001"
                    >Shenzhen VISA Component Index</a
                  >
                </li>
                <li>
                  <a
                    title="SZSE Composite Index"
                    data-id="1"
                    data-value="399106"
                    >SZSE VISA Composite Index</a
                  >
                </li>
                <li>
                  <a title="ChiNext Index" data-id="2" data-value="399006"
                    >ChiNext Index</a
                  >
                </li>
                <li>
                  <a title="Shenzhen 100 Index" data-id="3" data-value="399330"
                    >Shenzhen 100 Index</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="index-wrap">
            <div class="index-detail">
              <div
                :class="{
                  title: true,
                  down: this.last.change < 0 ? true : false,
                  up: this.last.change >= 0 ? true : false,
                }"
              >
                {{ numberFormat(this.last.last_threeall, true) }}
              </div>
              <ul>
                <li>
                  <span class="name">Prev.Close</span>
                  <span class="value">{{
                    this.last.threeall2
                      ? numberFormat(this.last.threeall2, true)
                      : this.last.threeall
                      ? numberFormat(this.last.threeall, true)
                      : numberFormat(this.last.open, true)
                  }}</span>
                </li>
                <li
                  :class="{
                    'open-down': this.last.change < 0 ? true : false,
                    'open-up': this.last.change >= 0 ? true : false,
                  }"
                >
                  <span class="name">Open</span>
                  <span class="value change">
                    <span class="value-cont">
                      <i class="icon"></i>
                      {{ numberFormat(this.last.open, true) }}
                    </span>
                  </span>
                </li>
                <li
                  :class="{
                    down: this.last.change < 0 ? true : false,
                    up: this.last.change >= 0 ? true : false,
                  }"
                >
                  <span class="name">Last</span>
                  <span class="value change">
                    <span class="value-cont">
                      <i class="icon"></i>
                      {{ numberFormat(this.last.last_threeall, true) }}
                    </span>
                  </span>
                </li>
                <li
                  :class="{
                    down: this.last.change < 0 ? true : false,
                    up: this.last.change >= 0 ? true : false,
                  }"
                >
                  <span class="name">Change</span>
                  <span class="value change">
                    <span class="value-cont">
                      <i class="icon"></i>
                      {{ numberFormat(this.last.last_twoall, true) }}
                    </span>
                  </span>
                </li>
                <li
                  :class="{
                    down: this.last.change < 0 ? true : false,
                    up: this.last.change >= 0 ? true : false,
                  }"
                >
                  <span class="name">Change（%）</span>
                  <span class="value change">
                    <span class="value-cont">
                      <i class="icon"></i>
                      {{ numberFormat(this.last.change, true) }}
                    </span>
                  </span>
                </li>
                <li>
                  <span class="name">High</span>
                  <span class="value blue">
                    {{ numberFormat(this.last.max, true) }}
                  </span>
                </li>
                <li>
                  <span class="name">Low</span>
                  <span class="value blue">
                    {{ numberFormat(this.last.min, true) }}
                  </span>
                </li>
                <li>
                  <span class="name">Volume（10Bil.）</span>
                  <span class="value">{{
                    numberFormat(this.last.volumn10bil, true)
                  }}</span>
                </li>
                <li>
                  <span class="name">Amount（100Mil.）</span>
                  <span class="value">{{
                    numberFormat(this.last.volumnmil, true)
                  }}</span>
                </li>
              </ul>
            </div>
            <div class="index-trend">
              <ul class="pic-tabs">
                <li class="active">
                  <a href="#">Real-time</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Daily</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Weekly</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Monthly</a>
                </li>
              </ul>
              <div class="tabs-content">
                <div
                  id="minutely"
                  class="tab-panel active"
                  _echarts_instance_="ec_1680574159124"
                  style="-webkit-tap-highlight-color: transparent; user-select: none; background: transparent;"
                >
                  <div
                    style="position: relative; overflow: hidden; width: 772px; height: 283px; padding: 0px; margin: 0px; border-width: 0px; cursor: default;"
                  >
                    <canvas
                      width="772"
                      height="283"
                      data-zr-dom-id="zr_0"
                      style="position: absolute; left: 0px; top: 0px; width: 772px; height: 283px; user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); padding: 0px; margin: 0px; border-width: 0px;"
                    ></canvas>
                  </div>
                  <div></div>
                </div>
              </div>
              <span class="update-time">{{
                showDate2(this.last.createdate)
              }}</span>
            </div>
          </div>
          <a class="loadingBtn"></a>
        </div>
      </div>
    </div>
    <div class="history">
      <h2 style="text-align: center;">
        SHENZHEN VISA Component Index Historical Data
      </h2>
      <table
        class="tbl"
        data-test="historical-data-table"
        style="--freeze-column-width:100px;"
      >
        <thead class="datatable_head__ysELt">
          <tr class="datatable_row__qHMpQ">
            <th class="datatable_cell__0y0eu">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white;">Date</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Price(Mor)</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Price(Aft)</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Open</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">High</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Low</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Vol</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Change %</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="datatable_body__cs8vJ">
          <tr
            class="datatable_row__qHMpQ"
            data-test="historical-data-table-row"
            v-for="(item, index) in data"
          >
            <td
              class="datatable_cell__0y0eu font-bold"
              style="text-align: center;"
            >
              {{ showDate(item.datetime) }}
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed"
              dir="ltr"
              :style="{ 'text-align': 'right', color: checkColor(item.twoall) }"
            >
              <template v-if="item.threeall">
                {{ numberFormat(item.threeall, true) }} ({{
                  numberFormat(item.twoall, true)
                }})
              </template>
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed"
              dir="ltr"
              :style="{ 'text-align': 'right', color: checkColor(item.change) }"
            >
              <template v-if="item.threeall2">
                {{ numberFormat(item.threeall2, true) }} ({{
                  numberFormat(item.twoall2, true)
                }})
              </template>
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
              style="text-align: right;"
            >
              {{ numberFormat(item.open, true) }}
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
              style="text-align: right;"
            >
              {{ numberFormat(item.max, true) }}
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
              style="text-align: right;"
            >
              {{ numberFormat(item.min, true) }}
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
              data-test="volume-cell"
              style="text-align: right;"
            >
              {{ numberFormat(item.volumn, true) }}M
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed font-bold"
              dir="ltr"
              :style="{ 'text-align': 'right', color: checkColor(item.change) }"
            >
              {{
                item.change.toString().charAt(0) === "-"
                  ? item.change
                  : "+" + item.change
              }}%
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="comprehensive" style="padding-top: 50px;">
      <div class="g-container socilogy-news socilogy-pc article-list">
        <ul id="wrap" class="wrap clearfix">
          <span
            class="bgImg1 bgImg"
            style='background-image: url("http://res.static.szse.cn/common/images/banner3.png"); display: none;'
          ></span>
          <span
            class="bgImg2 bgImg"
            style='background-image: url("http://res.static.szse.cn/common/images/banner3.png");'
          ></span>
          <span
            class="bgImg3 bgImg"
            style='background-image: url("http://res.static.szse.cn/common/images/banner3.png"); display: none;'
          ></span>

          <li style="width: 25%; border-left: 1px solid rgb(255, 255, 255);">
            <div class="ls" style="height: 1em; padding: 155px 20px 25px;">
              <div class="top_text ellipsis" style="font-size: 18px;">
                ESG practice of SZSE in a Chinese Manner: SZSE's efforts in
                poverty alleviation of Wushan County
              </div>
              <div class="bottom_text">
                <a class="arrow-right mt20 art-list-link" href="#"
                  ><span class="glyphicon glyphicon-menu-right"></span
                ></a>
              </div>
            </div>
          </li>

          <li
            class="no-after"
            style="width: 49.8%; border-left: 2px solid rgb(230, 0, 18);"
          >
            <div class="ls" style="height: 100%; padding: 50px 50px 10px;">
              <div class="top_text ellipsis" style="font-size: 28px;">
                Ecological Reconditioning Project in Deserts of Alashan
              </div>
              <div class="bottom_text">
                SZSE advocates the philosophy of public welfare and earnestly
                performed its social responsibility.It promotes educational
                programmes to enhance awareness of national conditions
                <a class="arrow-right mt20 art-list-link" href="#"
                  ><span class="glyphicon glyphicon-menu-right"></span
                ></a>
              </div>
            </div>
          </li>

          <li style="width: 25%; border-left: 1px solid rgb(255, 255, 255);">
            <div class="ls" style="height: 1em; padding: 155px 20px 25px;">
              <div class="top_text ellipsis" style="font-size: 18px;">
                Education and Social Welfare
              </div>
              <div class="bottom_text">
                SZSE supports public welfare and disaster relief. Since 1993,
                SZSE has donated nine primary schools in Hope Project.
                <a class="arrow-right mt20 art-list-link" href="#"
                  ><span class="glyphicon glyphicon-menu-right"></span
                ></a>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="socilogy-mobile">
        <ul class="wrap-mobile clearfix">
          <li
            style="background:http://res.static.szse.cn/common/images/banner3.png no-repeat left bottom;"
          >
            <div class="top_text ellipsis">
              ESG practice of SZSE in a Chinese Manner: SZSE's efforts in
              poverty alleviation of Wushan County
            </div>
            <div class="bottom_text">
              <a
                class="arrow-right mt20"
                href="./about/corporate/t20210111_584185.html"
                ><span class="glyphicon glyphicon-menu-right"></span
              ></a>
            </div>
          </li>

          <li
            style="background:http://res.static.szse.cn/common/images/banner3.png no-repeat left bottom;"
          >
            <div class="top_text ellipsis">
              Ecological Reconditioning Project in Deserts of Alashan
            </div>
            <div class="bottom_text">
              SZSE advocates the philosophy of public welfare and earnestly
              performed its social responsibility.It promotes educational
              programmes to enhance awareness of national conditions
              <a
                class="arrow-right mt20"
                href="./about/corporate/t20111029_559474.html"
                ><span class="glyphicon glyphicon-menu-right"></span
              ></a>
            </div>
          </li>

          <li
            style="background:http://res.static.szse.cn/common/images/banner3.png no-repeat left bottom;"
          >
            <div class="top_text ellipsis">
              Education and Social Welfare
            </div>
            <div class="bottom_text">
              SZSE supports public welfare and disaster relief. Since 1993, SZSE
              has donated nine primary schools in Hope Project.
              <a
                class="arrow-right mt20"
                href="./about/corporate/t20111101_559473.html"
                ><span class="glyphicon glyphicon-menu-right"></span
              ></a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="announce-wrap">
      <div class="g-container announce">
        <a href="./disclosures/announcements/">
          <img
            class="left-bg"
            src="http://res.static.szse.cn/common/images/enwebbg2.png"
            alt=""
          />
          <b>Company&nbsp;&nbsp;Announcements</b>
          <img
            class="right-arrow"
            src="http://res.static.szse.cn/common/images/icon_arrow_long_enweb.png"
            alt=""
          />
        </a>
      </div>
    </div>
    <footer class="enfooter">
      <div class="enfooter-content">
        <div class="g-container">
          <div class="footer-navlinks hidden-xs" style="text-align: center;">
            <a href="#">LISTING</a>
            <a href="#">DISCLOSURE</a>
            <a href="#">MARKET DATA</a>
            <a href="#">PRODUCTS</a>
            <a href="#">SERVICES</a>
            <a href="#">RULES</a>
            <a href="#">ABOUT</a>
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        <div class="g-container hidden-xs">
          <div class="footersafe-con">
            <img
              id="imgConac"
              src="http://res.static.szse.cn/common/icon/footer-icon-1.png"
            />
            <img
              src="http://res.static.szse.cn/common/icon/footer-icon-2.png"
            />
            <img
              src="http://res.static.szse.cn/common/icon/footer-icon-3.png"
            />
          </div>
          <div class="con">
            <div class="rule-tip">
              <a class="lawspublishlink" href="#" target="_blank "
                >Disclaimer</a
              >
              &nbsp;
              <a class="copyright-tip" href="#">ICP05012689</a
              ><span
                >©<span class="copyright-year">2023</span> Shenzhen Stock
                Exchange. All Rights Reserved</span
              >
            </div>
            <div class="ie-tip ">
              IE9.0+，1280*800
            </div>
          </div>
        </div>

        <div class="footer-copyright-mobile visible-xs ">
          <div class="footersafe-con">
            <a
              href="//bszs.conac.cn/sitename?method=show&amp;id=541B4DBFF36A103AE053022819ACAC5A"
            >
              <img
                id="imgConac"
                src="http://res.static.szse.cn/common/icon/footer-icon-1.png"
              />
            </a>
            <a href="http://www.sznet110.gov.cn/netalarm/index.jsp">
              <img
                src="http://res.static.szse.cn/common/icon/footer-icon-2.png"
              />
            </a>
            <a
              href="http://www.sznet110.gov.cn/webrecord/innernet/Welcome.jsp?bano=4403201010119"
            >
              <img
                src="http://res.static.szse.cn/common/icon/footer-icon-3.png"
              />
            </a>
          </div>
          <div class="con">
            <a class="lawspublishlink" href="#" target="_blank ">Disclaimer</a>
            &nbsp;<a href="#">ICP05012689</a> <br />©<span
              class="copyright-year"
              >2023</span
            >
            Shenzhen Stock Exchange.
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "@/axios.ts";
import moment from "moment-timezone";

export default {
  name: "Home",
  mounted() {
    // this.getBackGround()
    // this.getLotto()
    // this.getBanners()
    // $("#idx_carousel").carousel({
    //   interval: 10000,
    // });
    this.date_show = moment()
      .tz("Asia/Bangkok")
      .format("MMM/DD/YYYY");
    this.getLotto();
    this.getLast();
  },
  computed: {},
  data() {
    return {
      last: [],
      status: "*Close",
      date_show: "",
      data_price: 0,
      data_prev: 0,
      chart_data: [],
      ChartPlot: "",
      data_label: [],
      data_time: [],
      first: "",
      data: [],
      picupdata: [],
    };
  },
  methods: {
    checkColor(num) {
      if (num) {
        if (Number(num) < 0) {
          return "red";
        } else {
          return "#0ea600";
        }
      }
    },
    numberFormat(x, decimal) {
      if (x) {
        if (decimal) {
          return Number(x).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        } else {
          return Number(x).toLocaleString(undefined);
        }
      }
    },
    showDate(datetime) {
      if (datetime) {
        return datetime.split(" ")[0];
      }
    },
    showDate2(datetime) {
      return moment(datetime)
        .add(1, "hours")
        .format("DD-MM-YYYY HH:mm");
    },
    async getLotto() {
      await axios.get("/list-v2/9").then((response) => {
        const data = response.data;

        if (data) {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];

            const d = element.datetime.split(" ");

            const date = d[0].split("-");
            const time = d[1].split(":");
            const datetime = Number(
              date[0] +
                "" +
                date[1] +
                "" +
                date[2] +
                "" +
                time[0] +
                "" +
                time[1]
            );
            if (
              moment(element.datetime)
                .add(-4, "hours")
                .add(-15, "minutes")
                .tz("Asia/Bangkok")
                .format("YYYYMMDDHHmm") >
              Number(
                moment()
                  .tz("Asia/Bangkok")
                  .format("YYYYMMDDHHmm")
              )
            ) {
              continue;
            }
            this.data.push(element);
          }
        }
        // setInterval(() => {
        //   this.getLast();
        // }, 1000);
      });

      // setInterval(this.getLastData, 1000);
    },
    formatDate(date) {
      const dd = moment(date)
        .tz("Asia/Bangkok")
        .format("DD");
      const year = moment(date)
        .tz("Asia/Bangkok")
        .format("YYYY");
      const month = this.convertToLaosMonth(
        moment(date)
          .tz("Asia/Bangkok")
          .format("MMMM")
      );
      return dd + " " + month + " " + year;
    },
    formatTime(date) {
      return moment(date)
        .tz("Asia/Bangkok")
        .format("HH:mm");
    },
    dateToday() {
      return moment()
        .tz("Asia/Bangkok")
        .format("dddd, DD MMMM YYYY");
    },
    async getLastData() {
      const now = moment()
        .tz("Asia/Bangkok")
        .format("HHmm");
      if (Number(now) >= 800 && Number(now) <= 1415) {
        this.status = "*Open";
      } else {
        this.status = "*Close";
      }
    },
    async getLast() {
      // label = 6
      const price = [];
      let date;
      if (
        Number(
          moment()
            .tz("Asia/Bangkok")
            .format("HHmm")
        ) <= 800
      ) {
        date = moment()
          .tz("Asia/Bangkok")
          .add(-1, "days")
          .format("YYYY-MM-DD");
      } else {
        date = moment()
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DD");
      }
      const n = {
        indexDetailTmp: $("#indexDetail").html(),
        minuteWrap: $("#minutely"),
        dayWrap: $("#daily"),
        weekWrap: $("#weekly"),
        monthWrap: $("#monthly"),
        trendTabs: $(".index-trend .pic-tabs"),
        code: "399001",
        charts: {},
        chartDataState: {},
      };

      await axios.get("/szse-chart").then((response) => {
        const data = response.data;
        let min = 0;
        let max = 0;
        this.picupdata = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          if (element) {
            this.last = element;
            const createdate1 = element.createdate.split(" ")[1];
            const time = createdate1.split(":");
            const time0 = (Number(time[0]) + 1).toString().padStart(2, "0");
            const amount100 = element.amount100mil.toString().split(".");
            const amount100mil = amount100[0] + "000";
            const amount100m2 = element.amount100m.toString().split(".");
            const amount100m = amount100m2[0] + amount100m2[1] + "027755.29";
            if (element.last_threeall) {
              this.picupdata.push([
                time0 + ":" + time[1],
                Number(element.last_threeall)
                  .toFixed(2)
                  .toString(),
                element.last_twoall.toFixed(2).toString(),
                element.change.toFixed(2).toString(),
                Number(amount100mil),
                // 3697911.0,
                Number(amount100m),
                // 5788027755.29
              ]);
            }
            min = element.min;
            max = element.max;
          }
        }
        const e = {
          datetime: moment()
            .tz("Asia/Bangkok")
            .add(1, "hours")
            .format("YYYY-MM-DD HH:mm"),
          code: "0",
          data: {
            code: "399001",
            name: "深证成指",
            close: this.last.last_threeall,
            marketTime: moment()
              .tz("Asia/Bangkok")
              .add(1, "hours")
              .format("YYYY-MM-DD HH:mm"),
            picupdata: this.picupdata,
            releInfo: {
              cfzqlb: "",
              zsdm: "399001",
              zslx: "",
              cfzqsclb: "",
            },
          },
          message: "成功",
        };
        var t = { data: e };
        if ($.isEmptyObject(n.charts.minuteChart)) {
          var a = new s.ui.controls.mLineChart(n.minuteWrap, t);
          n.charts.minuteChart = a;
        } else {
          n.charts.minuteChart.updateOption(t);
        }
      });
    },
  },
};
</script>

<style scoped>
.tbl {
  border-collapse: collapse;
  width: 94%;
  margin-left: 3%;
  margin-ringht: 3%;
}
.tbl th,
.tbl td {
  padding: 5px;
  border: solid 1px #777;
}
.tbl th {
  background-color: #162d64;
}
</style>
